import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'

Alpine.plugin(persist)
window.Alpine = Alpine

Alpine.store('settingsModal', {
  show: false,
  developmentMode: process.env.NODE_ENV === 'development',
  enableDevTools: Alpine.$persist(false).as('enableDevTools'),
  requireAllHintsUsed: Alpine.$persist(false).as('requireAllHintsUsed')
})

// must come after the settings modal store (because I can't figure out how to make typescript play nice with $persist)
import('./game').then(() => {
  Alpine.start()
})


